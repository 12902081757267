import { SlideCarousel } from './components/SlideCarousel';
import { ActiveSlide } from './components/slides/ActiveSlide';
import { NextSlide } from './components/slides/NextSlide';
import { SlideControls } from './components/SlideControls';
import { useSlideChangerStore, useSlideChangerActions } from './slide-changer-store';
import { useSlideChangerKeyboardEvents } from './hooks/use-handle-keyboard-events';

export const SlideChanger = {
  NextSlide,
  ActiveSlide,
  SlideCarousel,
  SlideControls,
  useSlideChangerStore,
  useSlideChangerActions,
  useSlideChangerKeyboardEvents,
};

export { useSlideChangerStore } from './slide-changer-store';
export type { SlideObject } from './slide-changer-store';

export * from './slide-changer-utils';
