import { cn } from '@inderes/videosync-ui';

import { useSlideChangerStore } from '../../slide-changer-store';
import { Slide } from './Slide';

export const NextSlide = () => {
  const nextSlide = useSlideChangerStore((state) => state.nextSlide);
  const isAllowedToChangeSlides = useSlideChangerStore((state) => state.isAllowedToChangeSlides);
  const activateNextSlide = useSlideChangerStore((state) => state.activateNextSlide);

  const onClickSlide = () => {
    activateNextSlide();
  };

  return (
    <div
      onClick={() => onClickSlide()}
      className={cn(
        'relative flex w-full flex-col gap-4 overflow-hidden !rounded-lg',
        isAllowedToChangeSlides && nextSlide && 'cursor-pointer',
      )}
    >
      {/* render next slide if there's a next slide, TODO: we should just pass slide... */}
      {nextSlide ? (
        <Slide slide={nextSlide} className="overflow-hidden rounded-2xl" />
      ) : (
        <div className="flex aspect-video w-full items-center justify-center rounded-lg border border-wsGray-light">
          {/* non breaking space */}
          No next slide
        </div>
      )}
    </div>
  );
};
