import { EventSlidesSet, EventLiveToolDataSessions, EventSyncSlides } from '@inderes/videosync-database';
import { RefObject } from 'react';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import {
  calculateNextSlide,
  calculatePreviousSlide,
  calculateLastActivatedSlide,
  flattenSlideSets,
  SlideWithImages,
  calculateNewSlideState,
} from './slide-changer-utils';

export interface SlideObject {
  slideSet: number;
  slide: number;
  slideIndex: number;
}

export interface InitializeParams {
  imageBaseUrl: string;
  liveToolDataSessions: EventLiveToolDataSessions[];
  slideSets: EventSlidesSet[];
  syncSlides?: EventSyncSlides[];
}

interface SlideChangerStoreVariables {
  initDone: boolean;
  imageBaseUrl: string;

  slideSets: EventSlidesSet[];
  slides: SlideWithImages[];
  syncSlides?: EventSyncSlides[];

  activeSlide: SlideObject | null;
  activeSlideSrc: string | null;
  nextSlide: SlideObject | null;
  previousSlide: SlideObject | null;
  isFirstSlide: boolean;
  isLastSlide: boolean;

  isAllowedToChangeSlides: boolean;

  carouselHoveredSlideRef: RefObject<HTMLDivElement> | null;
  carouselScrollPosition: number | null;
  hoveredSlide: SlideObject | null;
  isHoveringCarouselSlide: boolean;
  isHoveringHoveredSlide: boolean;
}
export interface SlideChangerStore extends SlideChangerStoreVariables {
  initialize: (params: InitializeParams) => void;
  resetStore: () => void;
  resolveSlideIndex: ({ slide, slideSet }: { slide: number; slideSet: number }) => number;
  resolveSyncSlideIndex: ({ slide, slideSet }: { slide: number; slideSet: number; time: number }) => number;

  setHoveredSlide: (slide: SlideObject | null) => void;
  setCarouselHoveredSlideRef: (ref: RefObject<HTMLDivElement>) => void;
  setCarouselScrollPosition: (position: number | null) => void;

  activateSlide: (slide: SlideObject, onChangeSlide?: (slide: SlideObject) => void) => void;
  activateNextSlide: (onChangeSlide?: (slide: SlideObject) => void) => void;
  activatePreviousSlide: (onChangeSlide?: (slide: SlideObject) => void) => void;

  setSlideSets: (slideSets: EventSlidesSet[]) => void;
  setIsAllowedToChangeSlides: (isAllowedToChangeSlides: boolean) => void;
  setIsHoveringCarouselSlide: (isHoveringCarouselSlide: boolean) => void;
  setIsHoveringHoveredSlide: (isHoveringHoveredSlide: boolean) => void;

  checkIfSlideNeedsToChangeWithSync: (playerCurrentTime: number) => void;
}

export const initialStoreValues: SlideChangerStoreVariables = {
  initDone: false,
  imageBaseUrl: '',

  slideSets: [],
  slides: [],
  activeSlide: null,
  activeSlideSrc: null,
  nextSlide: null,
  previousSlide: null,
  hoveredSlide: null,
  isFirstSlide: false,
  isLastSlide: false,
  carouselHoveredSlideRef: null,
  carouselScrollPosition: null,
  isAllowedToChangeSlides: false,
  isHoveringCarouselSlide: false,
  isHoveringHoveredSlide: false,
};

export const useSlideChangerStore = createWithEqualityFn<SlideChangerStore>()((set, get) => {
  return {
    ...initialStoreValues,
    initialize: ({ imageBaseUrl, liveToolDataSessions, slideSets, syncSlides }) => {
      set((state) => {
        const activeSlide = calculateLastActivatedSlide(liveToolDataSessions, slideSets, imageBaseUrl);
        const slides = flattenSlideSets(slideSets, imageBaseUrl);

        return {
          ...state,
          imageBaseUrl,
          slideSets: slideSets,
          slides,
          syncSlides,
          initDone: true,

          ...calculateNewSlideState(activeSlide, slideSets, imageBaseUrl),
        };
      });
    },
    resetStore: () => set(initialStoreValues),

    resolveSlideIndex: ({ slide, slideSet }) => {
      const { slides } = get();
      const slideIndex = slides.findIndex((slideItem) => slideItem.slide === slide && slideItem.slideSet === slideSet);
      return slideIndex;
    },
    resolveSyncSlideIndex: ({ slide, slideSet, time }) => {
      const { syncSlides } = get();
      const slideIndex = syncSlides?.findIndex(
        (slideItem) => slideItem.slide === slide && slideItem.slideSet === slideSet && slideItem.time === time,
      );
      return slideIndex || 0;
    },

    setHoveredSlide: (slide) =>
      set((state) => {
        return { ...state, hoveredSlide: slide };
      }),
    setCarouselHoveredSlideRef: (ref: RefObject<HTMLDivElement>) =>
      set((state) => {
        return { ...state, carouselHoveredSlideRef: ref };
      }),
    setCarouselScrollPosition: (position) => set((state) => ({ ...state, carouselScrollPosition: position })),
    setIsAllowedToChangeSlides: (value) => set((state) => ({ ...state, isAllowedToChangeSlides: value })),
    setSlideSets: (slideSets) =>
      set((state) => {
        return {
          ...state,
          slideSets,
          slides: flattenSlideSets(slideSets, state.imageBaseUrl),
        };
      }),

    activateSlide: (slide, onChangeSlide) =>
      set((state) => {
        // trigger callback
        if (onChangeSlide) onChangeSlide(slide);

        return {
          ...state,
          ...calculateNewSlideState(slide, state.slideSets, state.imageBaseUrl),
        };
      }),
    activatePreviousSlide: (onChangeSlide) =>
      set((state) => {
        // early retrun if presenter has no control
        // if (state.isAllowedToChangeSlides === false) return { ...state };
        if (state.activeSlide === null || state.previousSlide === null) return { ...state };

        const newSlide = calculatePreviousSlide(state.activeSlide, state.slideSets);
        // trigger callback
        if (newSlide && onChangeSlide) onChangeSlide(newSlide);

        return {
          ...state,
          ...calculateNewSlideState(newSlide, state.slideSets, state.imageBaseUrl),
        };
      }),
    activateNextSlide: (onChangeSlide) =>
      set((state) => {
        // early retrun if presenter has no control
        // if (state.isAllowedToChangeSlides === false) return { ...state };

        // can't go forward if there is no next slide
        if (state.nextSlide === null || state.activeSlide === null) return { ...state };

        const newSlide = calculateNextSlide(state.activeSlide, state.slideSets);
        if (newSlide && onChangeSlide) onChangeSlide(newSlide);

        return {
          ...state,
          ...calculateNewSlideState(newSlide, state.slideSets, state.imageBaseUrl),
        };
      }),

    setIsHoveringCarouselSlide: (isHoveringCarouselSlide: boolean) =>
      set((state) => ({ ...state, isHoveringCarouselSlide })),

    setIsHoveringHoveredSlide: (isHoveringHoveredSlide: boolean) =>
      set((state) => ({ ...state, isHoveringHoveredSlide })),

    checkIfSlideNeedsToChangeWithSync: (playerCurrentTime) =>
      set((state) => {
        const { activeSlide, syncSlides } = get();
        // early return if no syncSlides
        if (!syncSlides) return { ...state };
        // const syncSlide = syncSlides.findLast((slide) => slide?.time <= playerCurrentTime); // es2023
        const syncSlide = syncSlides
          .slice()
          .reverse()
          .find((slide) => slide?.time <= playerCurrentTime);
        if (!syncSlide) return { ...state };

        const syncSlideIndex = get().resolveSyncSlideIndex({
          slide: syncSlide.slide,
          slideSet: syncSlide.slideSet,
          time: syncSlide.time,
        });

        if (
          activeSlide?.slide !== syncSlide?.slide ||
          activeSlide?.slideSet !== syncSlide?.slideSet ||
          syncSlideIndex !== activeSlide?.slideIndex
        ) {
          // we need to change a slide
          return {
            ...state,
            ...calculateNewSlideState(
              {
                slide: syncSlide.slide,
                slideSet: syncSlide.slideSet,
                slideIndex: syncSlideIndex,
              },
              state.slideSets,
              state.imageBaseUrl,
            ),
          };
        }

        return { ...state };
      }),
  };
}, shallow);

export const useSlideChangerActions = () =>
  useSlideChangerStore(
    (state) => ({
      initialize: state.initialize,
      activateSlide: state.activateSlide,
      setSlideSets: state.setSlideSets,
      activateNextSlide: state.activateNextSlide,
      activatePreviousSlide: state.activatePreviousSlide,
    }),
    shallow,
  );
