'use client';

import { cn } from '@inderes/videosync-ui';
import { useMemo, useRef } from 'react';

import { SlideObject, useSlideChangerStore } from '../../slide-changer-store';
import { isTouchDevice } from '../../slide-changer-utils';
import { SlideImage } from './SlideImage';

interface SlideProps extends React.HTMLAttributes<HTMLDivElement> {
  slide: SlideObject;
  carouselSlide?: boolean;
  className?: string;
  active?: boolean;
}

export const Slide = ({ slide, carouselSlide = false, className = '', active = false }: SlideProps) => {
  const slideSets = useSlideChangerStore((state) => state.slideSets);
  const imageBaseUrl = useSlideChangerStore((state) => state.imageBaseUrl);
  const setCarouselHoveredSlideRef = useSlideChangerStore((state) => state.setCarouselHoveredSlideRef);
  const setHoveredSlide = useSlideChangerStore((state) => state.setHoveredSlide);
  const setIsHoveringCarouselSlide = useSlideChangerStore((state) => state.setIsHoveringCarouselSlide);
  const slideRef = useRef<HTMLDivElement | null>(null);

  // Slide image src
  const slideImageUrl = useMemo(() => {
    const fullImagePath = slideSets[slide.slideSet]?.images?.[slide.slide]?.full;
    if (!fullImagePath) return '';
    return `${imageBaseUrl}${fullImagePath}`;
  }, [slideSets, slide.slideSet, slide.slide, imageBaseUrl]);

  return (
    <div
      className={cn(
        'relative w-full flex-1',
        '',
        active ? 'border-4 border-red-700' : 'border-4 border-transparent',
        className,
      )}
      ref={slideRef}
      onMouseEnter={() => {
        if (carouselSlide && !isTouchDevice) {
          setCarouselHoveredSlideRef(slideRef);
          setHoveredSlide(slide);
          setIsHoveringCarouselSlide(true);
        }
      }}
      onMouseLeave={() => {
        if (carouselSlide && !isTouchDevice) {
          setIsHoveringCarouselSlide(false);
        }
      }}
      onClick={() => {
        if (carouselSlide && isTouchDevice) {
          setCarouselHoveredSlideRef(slideRef);
          setHoveredSlide(slide);
          setIsHoveringCarouselSlide(true);
        }
      }}
    >
      <SlideImage imgSrc={slideImageUrl} />
    </div>
  );
};
