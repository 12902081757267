'use client';

import { cn } from '@inderes/videosync-ui';
import { useState } from 'react';
import { FiArrowLeft, FiArrowRight, FiMaximize2 } from 'react-icons/fi';

import { SlideObject, useSlideChangerActions, useSlideChangerStore } from '../../slide-changer-store';
import { isTouchDevice } from '../../slide-changer-utils';
import { Slide } from './Slide';

export interface Props {
  showFullScreenButton?: boolean;
  showNavigationButtonsOnHover?: boolean;
  allowSlideChange?: boolean;
  onChangeSlide?: (slide: SlideObject) => void;
  className?: string;
}

export const ActiveSlide = ({
  showFullScreenButton = false,
  showNavigationButtonsOnHover = false,
  allowSlideChange = false,
  className = '',
  onChangeSlide,
}: Props) => {
  const activeSlide = useSlideChangerStore((state) => state.activeSlide);
  const isFirstSlide = useSlideChangerStore((state) => state.isFirstSlide);
  const isLastSlide = useSlideChangerStore((state) => state.isLastSlide);
  const { activateNextSlide, activatePreviousSlide } = useSlideChangerActions();
  const [hovering, setHovering] = useState(false);

  if (!activeSlide) return null;

  return (
    <div
      className={cn('relative', className)}
      onMouseEnter={(e) => {
        setHovering(true);
      }}
      onMouseLeave={(e) => {
        setHovering(false);
      }}
    >
      <Slide slide={activeSlide} className="overflow-hidden rounded-2xl" />

      {/* fullscreen button */}
      {showFullScreenButton && (
        <button
          onClick={() => void document.body.requestFullscreen()}
          className="absolute right-4 top-4 z-10 flex h-7 w-7 items-center justify-center !rounded-xl !bg-white !p-0 !text-black"
        >
          <FiMaximize2 className="text-[17px]" />
        </button>
      )}

      {/* navigation buttons */}
      {showNavigationButtonsOnHover && (hovering || isTouchDevice) && (
        <div className="absolute bottom-0 left-4 right-4 top-0 flex items-center justify-between">
          <button
            onClick={() => activatePreviousSlide(onChangeSlide)}
            disabled={isFirstSlide || allowSlideChange === false}
            className="h-8 w-8 cursor-pointer text-wsTeal-lighter disabled:opacity-40"
          >
            <div className="flex h-full w-full items-center justify-center rounded-full border-2 border-wsTeal bg-wsBlue">
              <FiArrowLeft className="h-6 w-6" />
            </div>
          </button>

          <button
            onClick={() => activateNextSlide(onChangeSlide)}
            disabled={isLastSlide || allowSlideChange === false}
            className="h-8 w-8 cursor-pointer text-wsTeal-lighter disabled:opacity-40"
          >
            <div className="flex h-full w-full items-center justify-center rounded-full border-2 border-wsTeal bg-wsBlue">
              <FiArrowRight className="h-6 w-6" />
            </div>
          </button>
        </div>
      )}
    </div>
  );
};
