'use client';

import { useEffect, useMemo, useRef, useState } from 'react';
import { Navigation, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

import { SlideObject, useSlideChangerStore } from '../slide-changer-store';
import { Slide } from './slides/Slide';
import { SlideCarouselHoveredSlide } from './slides/SlideCarouselHoveredSlide';

import 'swiper/css/bundle';

export interface Props {
  onChangeSlide: (slide: SlideObject) => void;
  allowSlideChange: boolean;
}

export const SlideCarousel = ({ onChangeSlide, allowSlideChange }: Props) => {
  const [slides, setSlides] = useState<SlideObject[]>([]);
  const activeSlide = useSlideChangerStore((state) => state.activeSlide);
  const slideSets = useSlideChangerStore((state) => state.slideSets);
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
  // const [swiper, setSwiper] = useState<Swiper | null>(null);
  const [swiper, setSwiper] = useState<any | null>(null);
  const carouselRef = useRef<HTMLDivElement | null>(null);

  // when activeSlide changes in the store, update swiper
  useEffect(() => {
    swiper && activeSlide && swiper.slideTo(activeSlide?.slide);
  }, [activeSlide, swiper]);

  // flatten slidesets to slides
  useMemo(() => {
    const newSetOfSlides: SlideObject[] = [];
    slideSets?.forEach((slideSet, slideSetIndex) => {
      return slideSet.images.forEach((image, imageIndex) => {
        newSetOfSlides.push({
          slide: imageIndex,
          slideSet: slideSetIndex,
          slideIndex: newSetOfSlides.length,
        });
      });
    });
    setSlides(newSetOfSlides);
  }, [slideSets]);

  useEffect(() => {
    const index = slides.findIndex((slide) => {
      if (slide.slide === activeSlide?.slide && slide.slideSet === activeSlide.slideSet) {
        return true;
      }
    });
    swiper?.slideTo(index);
  }, [activeSlide, slides, swiper]);

  return (
    <div className="relative grid" ref={carouselRef}>
      <SlideCarouselHoveredSlide
        allowSlideChange={allowSlideChange}
        onChangeSlide={onChangeSlide}
        carouselRef={carouselRef}
      />

      <Swiper
        // install Swiper modules
        modules={[Navigation, A11y]}
        spaceBetween={10}
        slidesPerView={5}
        slidesPerGroup={4}
        // this is needed to make swiper layout work correctly
        className="w-full"
        // we use custom buttons
        navigation={{ nextEl, prevEl }}
        grabCursor={true}
        onSwiper={(swiper) => setSwiper(swiper)}
      >
        {/* loop over the slides */}
        {slides?.map((slide) => (
          // every slide needs to be wrapped in a SwiperSlide
          <SwiperSlide key={`slideCarouselSlide-${slide.slideSet}-${slide.slide}`} className="">
            <Slide
              slide={slide}
              carouselSlide={true}
              active={activeSlide?.slide === slide.slide && activeSlide.slideSet === slide.slideSet}
              className="overflow-hidden rounded-2xl"
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* prev button */}
      <button
        ref={(node) => setPrevEl(node)}
        className="absolute left-3 top-0 z-20 flex h-full cursor-pointer items-center text-wsTeal-lighter disabled:opacity-40"
      >
        <FiArrowLeft className="h-6 w-6 rounded-full border-2 border-wsTeal bg-wsBlue" />
      </button>

      {/* next button */}
      <button
        ref={(node) => setNextEl(node)}
        className="absolute right-3 top-0 z-10 flex h-full cursor-pointer items-center  text-wsTeal-lighter disabled:opacity-40"
      >
        <FiArrowRight className="h-6 w-6 rounded-full border-2 border-wsTeal bg-wsBlue" />
      </button>
    </div>
  );
};
