import { FiArrowRightCircle, FiArrowLeftCircle } from 'react-icons/fi';

import { SlideObject, useSlideChangerStore } from '../slide-changer-store';
import { cn } from '@inderes/videosync-ui';

export interface SlideControlButtonProps {
  onClick: () => void;
  disabled: boolean;
  children: React.ReactNode;
}

const SlideControlButton = ({ onClick, disabled, children }: SlideControlButtonProps) => {
  return (
    <button onClick={onClick} disabled={disabled} className="text-wsTeal-lighter disabled:cursor-not-allowed">
      {children}
    </button>
  );
};

export interface SlideControlsProps {
  onChangeSlide: (slide: SlideObject) => void;
  isAllowedToChangeSlides: boolean;
  className?: string;
}

export const SlideControls = ({ onChangeSlide, className = '', isAllowedToChangeSlides }: SlideControlsProps) => {
  const { nextSlide, previousSlide, activateNextSlide, activatePreviousSlide } = useSlideChangerStore((state) => state);

  const onClickPreviousSlideButton = () => {
    activatePreviousSlide(onChangeSlide);
  };
  const onClickNextSlideButton = () => {
    activateNextSlide(onChangeSlide);
  };

  return (
    <div className={cn(className)}>
      <SlideControlButton
        onClick={onClickPreviousSlideButton}
        disabled={isAllowedToChangeSlides === false || previousSlide === null}
      >
        <FiArrowLeftCircle className="mr-3 h-6 w-6" />
      </SlideControlButton>

      <SlideControlButton
        onClick={onClickNextSlideButton}
        disabled={isAllowedToChangeSlides === false || nextSlide === null}
      >
        <FiArrowRightCircle className="h-6 w-6" />
      </SlideControlButton>
    </div>
  );
};
