'use client';

import { cn, useOnClickOutside } from '@inderes/videosync-ui';
import { useMemo, useRef } from 'react';

import { SlideObject, useSlideChangerStore } from '../../slide-changer-store';
import { isTouchDevice } from '../../slide-changer-utils';

interface Props {
  onChangeSlide: (slide: SlideObject) => void;
  allowSlideChange: boolean;
  carouselRef: React.RefObject<HTMLDivElement>;
}

export const SlideCarouselHoveredSlide = ({ onChangeSlide, allowSlideChange, carouselRef }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const hoveredSlide = useSlideChangerStore((state) => state.hoveredSlide);
  const imageBaseUrl = useSlideChangerStore((state) => state.imageBaseUrl);
  const setHoveredSlide = useSlideChangerStore((state) => state.setHoveredSlide);
  const setIsHoveringHoveredSlide = useSlideChangerStore((state) => state.setIsHoveringHoveredSlide);
  const isHoveringCarouselSlide = useSlideChangerStore((state) => state.isHoveringCarouselSlide);
  const isHoveringHoveredSlide = useSlideChangerStore((state) => state.isHoveringHoveredSlide);
  const carouselHoveredSlideRef = useSlideChangerStore((state) => state.carouselHoveredSlideRef);
  const activateSlide = useSlideChangerStore((state) => state.activateSlide);
  const slideSets = useSlideChangerStore((state) => state.slideSets);

  const onClickActivateSlideButton = (slideSet: number, slide: number, slideIndex: number) => {
    activateSlide({ slideSet, slide, slideIndex }, onChangeSlide);
    setHoveredSlide(null);
  };

  const slideImageUrl = useMemo(() => {
    if (!hoveredSlide) return '';
    const fullImagePath = slideSets[hoveredSlide.slideSet]?.images?.[hoveredSlide.slide]?.full;
    if (!fullImagePath) return '';
    return `${imageBaseUrl}${fullImagePath}`;
  }, [hoveredSlide, slideSets, imageBaseUrl]);

  // Handle clicking outside of the hovered slide on touch device
  useOnClickOutside(ref, () => isTouchDevice && setHoveredSlide(null));

  if (
    !carouselHoveredSlideRef?.current ||
    !carouselRef.current ||
    !hoveredSlide ||
    (!isHoveringCarouselSlide && !isHoveringHoveredSlide)
  ) {
    return <></>;
  }

  const { width: carouselSlideWidth, height: carouselSlideHeight } =
    carouselHoveredSlideRef.current.getBoundingClientRect();

  const parentPosition = carouselRef.current.getBoundingClientRect();
  const position = carouselHoveredSlideRef.current.getBoundingClientRect();

  const relativeLeftPosition = position.left - parentPosition.left;

  return (
    <div
      // for this absolute position to work, the parent element must have position: relative
      className={`absolute z-30 flex w-96 flex-col text-black`}
      style={{
        left: relativeLeftPosition - carouselSlideWidth / 2,
        // small overlap with the carousel slide, so the mouse can move from the carousel slide to the hovered slide
        bottom: Math.floor(carouselSlideHeight) - 2,
        paddingBottom: 18,
      }}
      onMouseEnter={() => !isTouchDevice && setIsHoveringHoveredSlide(true)}
      onMouseLeave={() => !isTouchDevice && setIsHoveringHoveredSlide(false)}
      ref={ref}
    >
      <div className="rounded-2xl border border-wsTeal-lighter">
        <img
          alt="presentation slide"
          draggable={false}
          src={slideImageUrl}
          className="h-full w-full rounded-t-2xl object-contain"
        />
        <button
          className={cn(
            'w-full rounded-b-2xl bg-wsBlue p-4 text-base text-wsTeal-lighter',
            allowSlideChange ? 'hover:bg-wsBlue-light' : 'cursor-not-allowed bg-gray-600 text-gray-400',
          )}
          disabled={allowSlideChange === false}
          onClick={() => {
            allowSlideChange &&
              onClickActivateSlideButton(hoveredSlide?.slideSet, hoveredSlide?.slide, hoveredSlide?.slideIndex);
          }}
        >
          Activate slide
        </button>
      </div>
    </div>
  );
};
