'use client';

import { useEffect, useCallback } from 'react';
import { SlideObject, useSlideChangerStore } from '../slide-changer-store';

export interface Params {
  isAllowedToChangeSlides: boolean;
  onChangeSlide: (slide: SlideObject) => void;
}

export const useSlideChangerKeyboardEvents = ({ isAllowedToChangeSlides, onChangeSlide }: Params) => {
  const activateNextSlide = useSlideChangerStore((state) => state.activateNextSlide);
  const activatePreviousSlide = useSlideChangerStore((state) => state.activatePreviousSlide);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      console.log('useCallback - handleKeyPress');

      if (
        event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLTextAreaElement ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
      )
        return;
      switch (event.key) {
        case 'ArrowUp':
        case 'PageUp':
        case 'ArrowLeft':
          if (isAllowedToChangeSlides) {
            activatePreviousSlide(onChangeSlide);
          }
          break;
        case 'PageDown':
        case 'ArrowRight':
        case 'ArrowDown':
          if (isAllowedToChangeSlides) {
            activateNextSlide(onChangeSlide);
          }
          break;

        default:
          break;
      }
    },
    [activateNextSlide, activatePreviousSlide, isAllowedToChangeSlides, onChangeSlide],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);
};
