import { EventSlidesSet, EventLiveToolDataSessions } from '@inderes/videosync-database';
import { SlideObject } from './slide-changer-store';

export type SlideWithImages = SlideObject & { images: { full: string; medium: string; thumbnail: string } };

export const calculateNextSlide = (activeSlide: SlideObject | null, slideSets: EventSlidesSet[]) => {
  if (!activeSlide || slideSets.length === 0) return null;

  const currentSlideSet = slideSets[activeSlide.slideSet];
  const nextSlide = { ...activeSlide };

  // if we're at the end of the slide set, go to the next slide set
  if (currentSlideSet && activeSlide.slide === currentSlideSet.images.length - 1) {
    // if we're at the end of the last slide set
    if (activeSlide.slideSet === slideSets.length - 1) {
      return null;
    } else {
      // we have next set, assign the first of it
      nextSlide.slideSet = activeSlide.slideSet + 1;
      nextSlide.slide = 0;
    }
  } else {
    // just move to the next slide
    nextSlide.slide = activeSlide.slide + 1;
  }

  nextSlide.slideIndex = activeSlide.slideIndex + 1;

  return nextSlide;
};

export const calculatePreviousSlide = (activeSlide: SlideObject | null, slideSets: EventSlidesSet[]) => {
  if (!activeSlide) return null;

  const previousSlide = { ...activeSlide };

  // if we're at the beginning of the slide set, go to the previous slide set
  if (activeSlide.slide === 0) {
    // if we're at the beginning of the first slide set, go to the last slide set
    if (activeSlide.slideSet === 0 || activeSlide.slideIndex === 0) {
      return null;
    } else {
      previousSlide.slideSet = activeSlide.slideSet - 1;
      previousSlide.slide = slideSets[previousSlide.slideSet].images.length - 1;
    }
  } else {
    previousSlide.slide = activeSlide.slide - 1;
  }
  previousSlide.slideIndex = activeSlide.slideIndex - 1;

  return previousSlide;
};
// utility to flatten slidesets[] to slides array
export const flattenSlideSets = (slideSets: EventSlidesSet[], imageBaseUrl: string) => {
  const slides: SlideWithImages[] = [];
  // flatten slidesets to slides
  slideSets?.forEach((slideSet, slideSetIndex) =>
    slideSet.images.forEach((image, imageIndex) => {
      slides.push({
        slide: imageIndex,
        slideSet: slideSetIndex,
        slideIndex: slides.length,
        images: {
          full: `${imageBaseUrl}${image.full}`,
          medium: `${imageBaseUrl}${image.medium}`,
          thumbnail: `${imageBaseUrl}${image.thumbnail}`,
        },
      });
    }),
  );
  return slides;
};


export const calculateLastActivatedSlide = (
  liveToolDataSessions: EventLiveToolDataSessions[],
  slideSets: EventSlidesSet[],
  imageBaseUrl: string,
): SlideObject => {
  const activeSlide = { slideSet: 0, slide: 0, slideIndex: 0 };
  if (liveToolDataSessions && (liveToolDataSessions.length || 0) > 0) {
    // Last session
    const lastSession = (liveToolDataSessions?.length || 0) - 1;
    const slidesLength = liveToolDataSessions?.[lastSession].sync?.slides?.length || 0;
    // If last session has slides
    if (slidesLength > 0) {
      const lastSlidesObject = liveToolDataSessions?.[lastSession]?.sync?.slides?.[slidesLength - 1];
      // If lastSlidesObject is missing, return first slide
      if (!lastSlidesObject) return activeSlide;

      if (lastSlidesObject.slide !== null && lastSlidesObject?.slideSet !== null) {
        /* Check that a slide with lastSlidesObject's slideSet & slide indices exist
        (e.g. previous live session might have had longer slidesets or more of them) */
        if (slideSets?.[lastSlidesObject.slideSet]?.images?.[lastSlidesObject.slide] === undefined) {
          return activeSlide;
        }
      const slides =  flattenSlideSets(slideSets, imageBaseUrl);
        // Find the slideIndex of the last activated slide
        const lastActivatedSlide = slides.find(
          (slide) => slide.slide === lastSlidesObject.slide && slide.slideSet === lastSlidesObject.slideSet,
        );
        activeSlide.slideSet = Number(lastActivatedSlide?.slideSet);
        activeSlide.slide = Number(lastActivatedSlide?.slide);
        activeSlide.slideIndex = Number(lastActivatedSlide?.slideIndex); 
      }
    }
  }
  return activeSlide;
};


export const calculateNewSlideState = (
  newSlide: SlideObject | null,
  slideSets: EventSlidesSet[],
  imageBaseUrl: string,
) => {
  // early return if no new slide
  if (!newSlide)
    return {
      activeSlide: null,
      activeSlideSrc: null,
      nextSlide: null,
      previousSlide: null,
      isFirstSlide: false,
      isLastSlide: false,
    };

  const activeSlide = newSlide;
  const activeSlideImagePath = slideSets[newSlide.slideSet]?.images?.[newSlide.slide]?.full;
  const activeSlideSrc = activeSlideImagePath ? `${imageBaseUrl}${activeSlideImagePath}` : null;
  const nextSlide = calculateNextSlide(newSlide, slideSets);
  const previousSlide = calculatePreviousSlide(newSlide, slideSets);

  return {
    activeSlide,
    activeSlideSrc,
    nextSlide,
    previousSlide: calculatePreviousSlide(newSlide, slideSets),
    isFirstSlide: previousSlide === null,
    isLastSlide: nextSlide === null,
  };
};

// Check if device is touch device (note: doesn't update before refresh if you switch to emulator in devtools)
// export const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
export const isTouchDevice =
  typeof window !== 'undefined' && ('ontouchstart' in window || navigator.maxTouchPoints > 0);
