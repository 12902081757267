'use client';

import { ReactNode } from 'react';
import { IoProvider } from 'socket.io-react-hook';

type SocketServiceProviderProps = {
  children?: ReactNode;
};

export const SocketServiceProvider = ({ children }: SocketServiceProviderProps) => {
  return <IoProvider>{children}</IoProvider>;
};
